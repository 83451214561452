import React, { Component } from 'react';
import './App.css';
import { useHistory } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBFooter,
  MDBNavLink,
  MDBTooltip,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactComponent as Logo } from './assets/logo.svg';
import Routes from './Routes';
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import {createBrowserHistory} from 'history';
import { size } from 'min-dash';
export const history = createBrowserHistory({forceRefresh:true})

class MainHeader extends Component {
  state = {
    collapseID: '',
    modal: false
  };

  toggleCollapse = collapseID => () =>{
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));
    }

  closeCollapse = collID => () => {
    const { collapseID } = this.state;
    window.scrollTo(0, 0);
    collapseID === collID && this.setState({ collapseID: '' });
  };

  logout() {
    sessionStorage.clear();
    history.push('/');
  }

  // Modal code
  toggle = () => {
    if (sessionStorage.getItem('userid')) {
        this.setState({
        modal: !this.state.modal
      });
    }
  }


  render() {
    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('mainNavbarCollapse')}
      />
    );
    const { collapseID } = this.state;

    return (
    
        <div className='flyout'>
          <MDBNavbar className='Navcolor' color='p-1' dark expand='md' fixed='top' scrolling >
            {/* created a new css class nav-cust-col with background color #036667 with !important and removed [color='indigo darken-1 p-1' dark] from the MDBNavbar tag  */}

            {/* <MDBNavbarBrand href='/' className='py-0 font-weight-bold'>
              <img src="./chime-icon.png" style={{ height: '2rem', width: '2rem', padding: '0.2rem' }} />
              <strong className='align-middle'>THI CHIME</strong>
            </MDBNavbarBrand>
            */}
         <MDBNavbarNav left>
              <MDBNavItem>
                {/* <div style={{height: '2.5rem'}}> */}
                  {/* <MDBBtn id="logout" color='indigo p-1' style={{height: '2.1rem'}} className='py-0 font-weight-bold' onClick={() => this.logout()}> */}
                  <a  href='./modules'  gradient="aqua" style={{height: '2.3rem'}} className='py-0 font-weight-bold'>
                  <img src="./udbhata.png" style={{marginLeft: '5px', height: '2rem', width: '6rem', padding: '0.2rem' }} />
                    {/* <strong className='mr-2 white-text align-middle'></strong> */}
                  </a>
                {/* </div> */}
              </MDBNavItem>
            </MDBNavbarNav>

            

            <MDBNavbarToggler
              onClick={this.toggleCollapse('mainNavbarCollapse')}
            />
            <MDBCollapse id='mainNavbarCollapse' isOpen={collapseID} navbar>
              <MDBNavbarNav right>
                
                <MDBNavItem>
                 {
                 (sessionStorage.getItem('userid')) ? 
                  (<MDBNavLink
                    exact
                    to='./tasks'>
                    <strong>Pending tasks</strong>
                  </MDBNavLink>): ""
                }
                </MDBNavItem>

                <MDBNavItem>
                {
                 (sessionStorage.getItem('userid')) ? 
                  (<MDBNavLink
                    exact
                    to='./modules' >
                    <strong>Modules</strong>
                  </MDBNavLink>): ""
                }
                </MDBNavItem>
                {/* <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/css'
                  >
                    <strong>CSS</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/components'
                  >
                    <strong>Components</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/advanced'
                  >
                    <strong>Advanced</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/navigation'
                  >
                    <strong>Navigation</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/forms'
                  >
                    <strong>Forms</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/tables'
                  >
                    <strong>Tables</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/modals'
                  >
                    <strong>Modals</strong>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/addons'
                  >
                    <strong>Addons</strong>
                  </MDBNavLink>
                </MDBNavItem> */}

                {/* PRO-START */}
                {/* <MDBNavItem>
                  <MDBNavLink
                    onClick={this.closeCollapse('mainNavbarCollapse')}
                    to='/sections'
                  >
                    <strong>Sections</strong>
                  </MDBNavLink>
                </MDBNavItem> */}
                {/* PRO-END */}

                {/* <MDBNavItem>
                  <MDBTooltip
                    placement='bottom'
                    domElement
                    style={{ display: 'block' }}
                  >
                    <a
                      className='nav-link Ripple-parent'
                      href='https://mdbootstrap.com/products/react-ui-kit/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <strong>
                        <MDBIcon far icon='gem' />
                      </strong>
                    </a>
                    <span>PRO</span>
                  </MDBTooltip>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBTooltip
                    placement='bottom'
                    domElement
                    style={{ display: 'block' }}
                  >
                    <a
                      className='nav-link Ripple-parent'
                      href='https://mdbootstrap.com/docs/react/getting-started/download/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <strong>
                        <MDBIcon icon='download' />
                      </strong>
                    </a>
                    <span>FREE</span>
                  </MDBTooltip>
                </MDBNavItem> */}
                {/* <MDBNavItem>
                {
                  (sessionStorage.getItem('userid')) ? 
                  (<MDBNavLink
                    exact
                    to='' disabled>
                    <strong className='mr-4 white-text'><span style={{borderRadius: '1px', padding: '1px'}}>Welcome <span style={{color: '#F3EF00'}}>{ sessionStorage.getItem('userid') }</span></span></strong>
                  </MDBNavLink>) : ""
                } */}

                {/* <MDBNavLink
                      exact
                      to='' disabled>
                       <strong className='mr-4 white-text'><span style={{borderRadius: '1px', padding: '1px'}}>{(sessionStorage.getItem('userid')) ? ('Welcome '+ sessionStorage.getItem('userid')) : ""}</span></strong>
                    </MDBNavLink> */}

                {/* </MDBNavItem> */}

                <MDBDropdown>
                  {/* <MDBIcon fas icon="user"  id='logout' style={{color: 'white'}}></MDBIcon> */}
                  <MDBDropdownToggle class="nav-link Ripple-parent fas fa-user-alt" id='logout' style={{color: 'white', background: 'none', borderRadius: 'none', marginTop:'5px',marginLeft: '12px'}}></MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link><strong className='mr-4 white-text'><span style={{borderRadius: '1px', padding: '1px'}}><span style={{color: 'black'}}>{ sessionStorage.getItem('userid') }</span></span></strong></MDBDropdownItem>
                    <MDBDropdownItem onClick={this.toggle}>Logout</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>



                <MDBNavItem className='mr-2'>
                {  
                  (sessionStorage.getItem('userid')) ? 
                    (

                    <div style={{marginTop:'0.3rem'}}>
                      <strong>
                        {/* <i class="nav-link Ripple-parent fas fa-user" id='user' onClick={this.toggle}></i> */}
                        </strong>
                        {/* <span>Log-Out</span> */}
                        </div>
                    ): ""
                }
                </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
          

          {/* Pop-up modal for logout confirmation */}
          <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                          <MDBModalHeader style={{fontSize : '2rem'}} toggle={this.toggle}><b>Confirm Logout</b></MDBModalHeader>
                            <MDBModalBody>
                              Are you sure you want to logout?
                            </MDBModalBody>
                          <MDBModalFooter>
                            <MDBBtn color="info" onClick={this.toggle}>Cancel</MDBBtn>
                            <MDBBtn color='success' onClick={() => this.logout()} style={{color: 'black'}}>logout</MDBBtn>
                          </MDBModalFooter>
                        </MDBModal>
            {/* End of modal */}
         
          {collapseID && overlay}
          {/* <main style={{ marginTop: '3rem' }}>
            <Routes />
          </main> */}
          <MDBFooter  className='footer-copyright mb-0 py-1 text-center  w-100'>
            <p>
              &copy; {new Date().getFullYear()} Copyright:
                Powered by <a href='https://pragmatiq.in>'> Pragmatiq </a> 
            </p>
          </MDBFooter>
        </div>       
     
    );
  }
}

export default MainHeader;
