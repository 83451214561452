export function getEngineRestUrl() {
    let engineRestUrl;
    console.log('-----------',process.env.REACT_APP_chimeEnv )

    if(process.env.REACT_APP_chimeEnv === 'dev')
    {
    // for dev
        engineRestUrl = ((sessionStorage.getItem('engineRest')) ? sessionStorage.getItem('engineRest') : 'http://chime.pragmatiqinc.com/engine-rest');
    }else
    {
        //for prod
        engineRestUrl = ((sessionStorage.getItem('engineRest')) ? sessionStorage.getItem('engineRest') : 'http://chime.pragmatiqinc.com/engine-rest');
    }
    //engineRestUrl =  'http://chime.pragmatiqinc.com/engine-rest';
    //engineRestUrl = 'http://chime.pragmatiqinc.com'
    console.log('engineRestUrl- ' + engineRestUrl);
    //return process.env.ENGINE_REST ? process.env.ENGINE_REST : "http://chime.pragmatiqinc.com/engine-rest";
    // return process.env.ENGINE_REST ? process.env.ENGINE_REST : "http://chime.pragmatiqinc.com/engine-rest";
    return engineRestUrl;
}

export function getEngineRestUri() {
    let engineRestUri;
    console.log('-----------',process.env.REACT_APP_chimeEnv )
    if(process.env.REACT_APP_chimeEnv === 'dev'){
    
    // for dev
        engineRestUri = ((sessionStorage.getItem('engineRestUri')) ? sessionStorage.getItem('engineRestUri') : 'http://chime.pragmatiqinc.com/');
    }
    else
    {
        //for prod
        engineRestUri = ((sessionStorage.getItem('engineRestUri')) ? sessionStorage.getItem('engineRestUri') : 'http://chime.pragmatiqinc.com');
    }
   //engineRestUri =  'http://chime.pragmatiqinc.com/';
   //engineRestUri = 'http://chime.pragmatiqinc.com';
    console.log('engineRestUri- ' + engineRestUri);
    //return process.env.ENGINE_REST_URI ? process.env.ENGINE_REST_URI : "http://chime.pragmatiqinc.com/";
    //return process.env.ENGINE_REST_URI ? process.env.ENGINE_REST_URI : "http://chime.pragmatiqinc.com";
    return engineRestUri;
}