import React, {useEffect, useState } from 'react';
import queryString from 'query-string';
import { react } from '@babel/types';
import './Taskform.css';
import {  MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBCollapse, MDBNavItem, MDBContainer} from 'mdbreact';
import { ViewArrayOutlined } from '@material-ui/icons';
import {createBrowserHistory} from 'history';
import {getEngineRestUrl, getEngineRestUri} from './utils';
import MainHeader from '../MainHeader';
export const history = createBrowserHistory({forceRefresh:true})
export default function InitiateProcess(props){
    var CamSDK = window.CamSDK;
    //let history = useHistory();
    const clientObj = {
      mock: false,
      apiUri: getEngineRestUri()
    }
    var camClient = new CamSDK.Client(clientObj);
    const [query, setQuery] = useState({});
    var taskService = new camClient.resource('task');  
    useEffect(() => {
    let param = queryString.parse(props.location.search);
    setQuery(param);
    async function fetchProcForm(){
      try {
        
        //var url;
        if(param.pId){
        const response = await fetch(`${getEngineRestUrl()}/process-definition/key/${param.key}/startForm`);
        const data = await response.json();
        let url = data.key;
        // console.log('response', response)
        // console.log('data',data)
        // console.log('url here', url)
        if(url)
        {       
          console.log('entering here')   
          url = url.replace('embedded:deployment:', '/embedded-forms/forms/');
          url = getEngineRestUri() + url;
          loadStartForm(param.pId, url, param.key, function(err, camForm) {
           // console.log('printing',param.pId, url, param.key);
            if (err) {
              // throw err;
             // console.log('initiate ',err);
            }
            // console.log("form url - "+url);
            var $submitBtn = $('<button type="submit" class="cust-color btn form-btn">Submit</button>').click(function (e) {
             // console.log('entering in to submit button');
              camForm.submit(function (err) {
                if (err) {
                  throw err;
                }  
                console.log('error',err);

              }); 
                   e.preventDefault();
                  // if(param.key !== undefined)
               //   console.log('values', param.name, param.key, param.pId)
                  {history.push('/procsummary?name=' + param.name + '&key=' + param.key + '&pId=' + param.pId + "&procType=superP")}
            });
            
             camForm.containerElement.append($submitBtn);             
          });
        
        }
      }       
      }
      catch(err) {
        console.log("ERROR= "+err);
      }

    }
    fetchProcForm();
}, [])

function loadStartForm(processDefinitionId, url, processDefinitionKey, callback){
      //var url = '/embedded-forms/forms/start.html';
        new CamSDK.Form({
            client: camClient,
            formUrl: url, //'/embedded-forms/forms/start.html',
            // the process definition ID
            processDefinitionId: processDefinitionId, 
            processDefinitionKey: processDefinitionKey,
            containerElement: $('#myForm'),
            
            // continue the logic with the callback
            done: callback
        });
        //}
    //});
}
   return (
  <div>
    <MainHeader />
          {/* <MDBNavbar color="white p-1" expand="md" scrolling>
          <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
            <img
              src="./udbhata.png"
              style={{
                height: "2.5rem",
                width: "5rem",
                padding: "0.1rem",
                marginLeft: "10px",
              }}
            />
          </MDBNavbarBrand> 
        </MDBNavbar> */}

        <div className="flex-ct pt-4 p-3" style={{marginTop : '-2rem'}}>
              
              <h4 className="">{query.name}</h4>
              <div>
                <strong>
                  &copy; Powered by <img src='./chime-icon.png' width={20} height={20}/> Chime
                </strong>
              </div>
        </div>
        <hr className="hr hr-blurry" />


      <div className="m-3 p-3">
      <MDBContainer style={{marginTop: '6rem'}} >
      <form id="myForm"></form>
      </MDBContainer>
      </div>    
      </div>
   );
}

