import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './HomePage';
import Modules from '../src/chime/Modules'; 
import ProcessSummary from '../src/chime/ProcessSummary'; 
import ProcessDetails from '../src/chime/ProcessDetails'; 
import ShowDiagram from '../src/chime/ShowDiagram'; 
import TaskList from '../src/chime/TaskList'; 
import AdminPage from '../src/chime/AdminPage'; 
import Cockpit from '../src/chime/Cockpit'; 
import ProcessVariables from '../src/chime/ProcessVariables'; 
// import ViewProcessVariables from '../src/chime/ProcessVariables';
import TaskForm from './chime/TaskForm';
import Proofing from '../src/chime/Proofing'; 
import InitiateProcess from '../src/chime/StartProcess';
import Tasks from './chime/Tasks';
import LoadExcel from './chime/LoadExcel';
import GenerateXmlFromBpmn1  from './chime/GenerateXmlFromBpmn1';
class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/' component={HomePage} />
        {/* <Route path='/modules' component={GenerateXmlFromBpmn1} /> */}
        <Route path='/modules' component={Modules} />
        <Route path='/procsummary' component={ProcessSummary} />
        <Route path='/procdetails' component={ProcessDetails} />
        <Route path='/variables' component={ProcessVariables} />
        {/* <Route path='/Viewvariables' component={ViewProcessVariables} /> */}
        <Route path='/form' component={TaskForm} />
        <Route path='/showdiagram' component={ShowDiagram} />
        <Route path='/tasklist' component={TaskList} />
        <Route path='/admin' component={AdminPage} />
        <Route path='/cockpit' component={Cockpit} />
        <Route path='/proofing' component={Proofing} />
        <Route path='/initiateprocess' component= {InitiateProcess}/>
        <Route path='/tasks' component={Tasks} />
        <Route path='/viewrep' component={LoadExcel} />
        <Route
          render={function() {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
