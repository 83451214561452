import React, { Component }  from 'react';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import minimapModule from 'diagram-js-minimap';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-font/dist/css/bpmn-embedded.css';
import { emptyBpmn } from '../assets/empty.bpmn';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda';
import queryString from 'query-string';
import {getEngineRestUrl} from './utils';

class ShowDiagram extends Component {
    
    modeler = null;
    
    constructor(props) {
        super(props);
        this.state = {diagram: '', query: {}};
    }
    componentDidMount = () => {
        let param = queryString.parse(this.props.location.search);
        this.setState({query: param}); 
        fetch(`${getEngineRestUrl()}/process-definition/key/${param.key}/xml`) 
        .then(res => res.json()) 
        .then(data => { 
            this.newBpmnDiagram(data.bpmn20Xml);
            this.setState({diagram: data.bpmn20Xml}); 
            //console.log(data.bpmn20Xml);
        })
            this.modeler = new BpmnModeler({
                container: '#bpmnview',
                keyboard: {
                    bindTo: window
                }               
            }); 
//7617eecd-476d-11ec-838b-e670b83fd36f
        /* this.modeler = new BpmnModeler({
            container: '#bpmnview',
            keyboard: {
                bindTo: window
            },
            propertiesPanel: {
                parent: '#propview'
            },  
            additionalModules: [
                propertiesPanelModule,
                propertiesProviderModule, 
                minimapModule
            ],
            moddleExtensions: {
                camunda: camundaModdleDescriptor
            },
            // bpmnRenderer: {
            //     defaultFillColor: '#333',
            //     defaultStrokeColor: '#fff'
            // }
        }); */
        //this.newBpmnDiagram();
    }

    newBpmnDiagram = (xml) => {
        this.openBpmnDiagram(xml);
    }

    openBpmnDiagram = (xml) => {
        this.modeler.importXML(xml, (error) => {
            if (error) {
                return console.log('fail import xml', error);
            }
            
            var canvas = this.modeler.get('canvas');
            canvas.zoom('fit-viewport');
            
        });
    }

    render = () => {
        return(
            <div id="bpmncontainer">
                {//<div id="propview" style={{ width: '25%', height: '98vh', float: 'right', maxHeight: '98vh', overflowX: 'auto' }}></div>
                 }
                <div id="bpmnview" style={{ width: '90%', height: '98vh', float: 'left' }}></div>
            </div>
        )
    }
}

export default ShowDiagram;
