import React from "react";
import {getEngineRestUri, getEngineRestUrl} from './utils';
import { MDBContainer, MDBIframe } from "mdbreact";
import MainHeader from "../MainHeader";
// const AdminPage = () => {
//   //const adminPageUrl = `http://chime.pragmatiqinc.com/camunda/app/admin`;
//   //onClick="window.top.location.href = 'http://ipaddress/detailpage'" 
//   return (
//     <MDBContainer style={{ width: "100%", height: "100%", margin: 0, padding: 0 }}>
//       {/* <div style={{ width: "100%", height: "100%", margin: 0, padding: 0 }} dangerouslySetInnerHTML={{ __html: "<iframe style = margin: 0, padding: 0, top: 0, width: '1280px', height: '100%', overflow:hidden src='http://workflow.chime.pragmatiqinc.com/camunda/app/admin' sandbox='allow-top-navigation allow-scripts allow-forms' />"}}/> */}
//     <div onLoad= {() => window.top.location.href = 'http://workflow.chime.pragmatiqinc.com/camunda/app/admin'}></div>
//     </MDBContainer>

//   );
// }
const AdminPage = () => {
  return (
  <>
  <MainHeader />
 
    <MDBContainer style={{width: '100%',height: '100vh', margin: 0, padding: '5px'}}>
      <iframe style={{margin: 0, padding: 0, top: 0, width: '1280px', height: '100%'}} src="http://chime.pragmatiqinc.com/camunda/app/admin" />
    </MDBContainer>
     </>
  );
}
export default AdminPage;