import React, {useEffect } from 'react';
import {getEngineRestUrl} from './utils';
import dashboardSettings from '../mockdata/dashboardSettings.json';
import { MDBDataTableV5} from "mdbreact";
import queryString from "query-string";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
} from "mdbreact";
import { format } from "date-fns";
import {useHistory} from 'react-router-dom';

import { react } from '@babel/types';
import {  MDBContainer, MDBBtn, MDBInput, MDBIcon} from 'mdbreact';
import './Taskform.css';
import { formatDistance, subDays, parseISO } from 'date-fns';
import {createBrowserHistory} from 'history';
import {getEngineRestUri} from './utils';
import MainHeader from '../MainHeader';
export const history = createBrowserHistory({forceRefresh:true})
export default function ProcessVariables(props) {
    var cols = [
                {
                    label: 'Field Name',
                    field: 'name',
                    width: 150
                },
                {
                    label: 'Value',
                    field: 'value',
                    width: 150
                }                     
            ]
    const [query, setQuery] = React.useState({});
    const [data, setData] = React.useState({ columns: [], rows: [] });
    var CamSDK = window.CamSDK;
    const [userTasks, setUserTasks] = React.useState([]);
    const [isAdmin, setIsAdmin] = React.useState();
    const userId = sessionStorage.getItem('userid');
    const clientObj = {
      mock: false,
      apiUri: getEngineRestUri()
    }


    var camClient = new CamSDK.Client(clientObj);
    var taskService = new camClient.resource('task');
    useEffect( () => {
        let param = queryString.parse(props.location.search);
        setQuery(param); 
       // setIsAdmin(isAdminUser);
    }, []);
    useEffect( () => {
      let param = queryString.parse(props.location.search);
      setQuery(param); 
      // console.log('dashoboard-------------------', dashboardSettings.filter( element => element.processDefinition === param.taskName))
      // console.log('taskaname', param.taskName);
      // console.log('dashoboard-------------------', dashboardSettings.filter( element => element.processDefinition === param.taskName))
      let dashboardSettingsData= dashboardSettings.filter( element => element.processDefinition === param.taskName)[0]?.formFields;
      getProcessVariablesList(param,dashboardSettingsData)
  }, [])
    useEffect( () => {
      function fetchData()
      {
        localStorage.removeItem('liTaskId');
        const isAdminUser = sessionStorage.getItem('IsAdmin');
        setIsAdmin(isAdminUser);
        loadTasks(isAdminUser);
      }
      fetchData();
    }, [])
    function getJSONValue(fieldName, data)
    {
        if(data && data.length > 0)
        {
        const result = data.filter(el => {
            return el['field'] === fieldName;
         });
         if(result && result.length){
            return result[0].label;
         }
         else{
            return '';
         }
        }
        else{
            return '';
         }
    }
   async function getProcessVariablesList(param,dashboardSettingsData) {
        let fetchAPI;
        
        //console.log(`environment = ${process.env.REACT_APP_chimeEnv}`)
        if(process.env.REACT_APP_chimeEnv === 'dev'){//for dev env
          fetchAPI = `http://localhost:3300/formvariables/${param.key}`;
        } else {// for prod env
          fetchAPI = `http://chime.pragmatiqinc.com/formvariables/${param.key}`;
        }
        //console.log(dashboardSettingsData);
     //fetch(`${getEngineRestUrl()}/history/variable-instance?processInstanceId=${param.key}` )
       await fetch(fetchAPI)
            .then((res) => res.json())
            .then((recordset) => {             
                var r=[];
                //console.log(JSON.stringify(recordset[0].valueInfo));
                // recordset.map(rec => {
                //    (rec.type !== 'String') ? r.push({...rec, value: JSON.stringify(rec.value)}) : r.push(rec);
                // });
                
                recordset.map(rec => {
                    r.push({
                      
                        name: dashboardSettingsData.length > 0 ? getJSONValue(rec.name,dashboardSettingsData) : rec.name,
                        value: rec.value
                    });
                });
                var d = {
                    columns: cols,
                    rows: r}
                setData(d); 
                
            }); 
    }

    function loadTasks(isAdmin) {
        // fetch the list of available tasks
        let filterParams;
        //if(!sessionStorage.getItem('IsAdmin'))
        if(isAdmin === 'true')
          filterParams = {};
        else
          filterParams = {assignee: userId};
        taskService.list(filterParams, function (err, results) {
          
          if (err) {
            throw err;
          }
          let taskHdrs = dashboardSettings.map(({processDefinition,taskListHdr}) => ({processDefinition,taskListHdr}));
          const data = results._embedded.task;
         // console.log('data', data);
          const resultData = [];
          for(let i=0; i< data.length; i++){
              resultData.push({
              id: data[i].id,
              processInstanceId: data[i].processInstanceId,
              name: data[i].name,
              createdDate: data[i].created,
              processName: data[i]._embedded.processDefinition[0].name,
              processKey: data[i]._embedded.processDefinition[0].key,
              assignee: data[i].assignee,
              formattedDate:'Created ' + formatDistance(new Date(data[i].created),new Date(),{ addSuffix: true})
            });
          }        //let procIds =resultData.map(rd => rd.procId).join(',');
          if(resultData.length > 0)
          {
            // console.log("prociddetails",resultData);
    
            resultData.forEach(rec => {
            const taskListHdrRec = taskHdrs.find(findR => findR.processDefinition === rec.processKey);
            if(taskListHdrRec)
              rec['taskListHdr'] = taskListHdrRec.taskListHdr;
            });
            let procIds = getCommaSeperatedStringFromJsonArray(resultData, 'processInstanceId');
            fetchProcVariables(procIds, resultData);
          }
        });
      }
      
  function fetchProcVariables(procIds, resultData)
  {    
    //console.log('query checking', query);
    
    // console.log(sessionStorage.getItem('processInstanceId'))
    // console.log("--===-", resultData)
    // console.log("procid",procIds)
    // if(procIds == rec.id){
    //   console.log("taskid",rec.id)
    // }
   
    let procId = procIds.split(',')[0]
    fetch(`${getEngineRestUrl()}/history/variable-instance?processInstanceIdIn=${procId}&variableNameLike=nrO%`)
    .then((res) => res.json())
    .then((procVars) => { 
        resultData.forEach(res => {
          if(!(res.hasOwnProperty('taskListHdrVal')))
          {
            const taskListHdrValRec = procVars.find(rec => rec.processInstanceId === res.processInstanceId && rec.name === res.taskListHdr);
            if(taskListHdrValRec)
              res['taskListHdrVal'] = taskListHdrValRec.value;
          }
        });
        setUserTasks(resultData);
    });
  }
  function getCommaSeperatedStringFromJsonArray(jsonArray, mkey)
  {
    let uniqueBymkeyCommaSeperatedString = [...new Map(jsonArray.map(item => [item[mkey], item])).values()].map(item => item[mkey]).join(',');
    return uniqueBymkeyCommaSeperatedString;
  }
  useEffect(() => {
    async function fetchData() {
    let param = queryString.parse(props.location.search);
    //sessionStorage.removeItem('processInstanceId');
    sessionStorage.setItem('processInstanceId', param.key);
    setQuery(param);
    //console.log('Line 204',param);
    await getProcessList(param);
    //console.log('params', param.taskId);
    let taskid = param.taskId;
    showForm(taskid);
}
    fetchData();
  }, []);
  
  function showForm(taskid) {
    // loads the the task form
    // document.getElementById('userId').focus();
    localStorage.setItem('liTaskId', taskid);
    loadTaskForm(taskid, function(err, camForm) {
     // console.log('im the camform', camForm);
      if (err) {
        throw err;
      }
        var $submitBtn = $('<button type="submit" class="cust-color btn form-btn">Submit</button>').click(function (e) {
          camForm.submit(function (err) {
            //debugger;
            if (err) {
       //        console.log('error of submit', err)
              throw err;
            }
          });  
          //console.log('shf->  '+isAdmin)
          //console.log('processInstanceid in showform', processInstanceId)
          loadTasks(isAdmin);
          //clear the form
          $('#myForm').html('');
          history.push('/procdetails?key=' + sessionStorage.getItem('processInstanceId'));
        });
      
        var $saveBtn = $('<button type="button" class="cust-color btn form-btn">Save</button>').click(function (e) {
            
          //e.preventDefault();
         // camForm.on('store', function(err) {
          camForm.store(function(err) {
            if(err) {
              throw err;
            }
             e.storePrevented = false;
          });
           e.preventDefault();
             $('#myForm').html('');
          history.push('/procdetails?key=' + sessionStorage.getItem('processInstanceId'));
        });
        camForm.containerElement.append($submitBtn);      
        camForm.containerElement.append($saveBtn);             
    });
  }
  function loadTaskForm(taskId, callback) {
    // loads the task form using the task ID provided
  
    if(taskId === null || taskId === undefined){
      return;
    }
    taskService.form(taskId, function(err, taskFormInfo) {
      if(taskFormInfo!=null)
      {
        var url = (taskFormInfo.contextPath === null) ? taskFormInfo.key.replace('embedded:deployment:', '/embedded-forms/forms/') : taskFormInfo.key.replace('embedded:app:', taskFormInfo.contextPath + '/');
        taskFormInfo.contextPath = (taskFormInfo.contextPath !== null) ? taskFormInfo.contextPath : '/embedded-forms/';
        url = getEngineRestUri() + url;
        new CamSDK.Form({
          client: camClient,
          formUrl: url,//'/embedded-forms/forms/initiation.html', //
          taskId: taskId,//'096fec89-325d-11ec-8d12-e670b83fd36f', //
          containerElement: $('#myForm'),
          // continue the logic with the callback
          done: callback
        });
      }
    });
  }
  
//    function claimTask(taskId, assignee)
//    {
//     var userId = sessionStorage.getItem('userid');
//      var userdata = {
//        userId: userId
//   }
//      fetch(`${getEngineRestUrl()}/task/` + taskId + '/claim', {
//        method: 'POST',
//        headers: {
//          'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(userdata)
//   })
//     .then(res => {
//       console.log("1st then--" + res.status);
//       if(res){ res.json()}})
//     .catch((e) => {
//       alert('Error occured while claiming.'); 
//       console.log(e);
//     })
//     .then(res => {
//       console.log("2nd then--" +JSON.stringify(res))
//       if(res && res.type && res.type == "TaskAlreadyClaimedException")
//       {
//         alert("Task already claimed");
//       }else{
//         alert("Task claimed Successfully !!");
//       }

//     })
//     .catch((e) => {
//       alert('Error occured while task claim.'); 
//       console.log(e);
//   });
//   }
  async function getProcessList(param) {
   // console.log("key = ", param.key);
    fetch(`${getEngineRestUrl()}/history/task?processInstanceId=${param.key}`)
      .then((res) => res.json())
      .then((recordset) => {
     //   console.log('entered into recordset',recordset)
        var r = [];
        recordset.map((rec) => {
       //   console.log('--==',rec.id, 'param key', param.key)
          // r.push({...rec, id : <a className='blue-text' href={'/form?key='+ rec.id + '&taskStatus=' + ((rec.deleteReason === null) ? 'pending': 'completed')}>View</a>})
          r.push({
            ...rec,
            id:
            <a className="blue-text" href={`/variables?key=${param.key}&taskId=${rec.id}`}>
            Edit
          </a>
          
    //     <a className="blue-text" href={`/tasks`}>
    //     View
    //   </a>
    ,
   
              // rec.deleteReason === null ? (
              //   <a className="blue-text" href={"/form?key=" + rec.id + "&name=" + rec.name}>
              //     View
              //   </a>
              // ) : (
              //   <a className="blue-text" href={"/variables?key=" + param.key}>
              //     View
              //   </a>
              // ),
             
              deleteReason: (
               
                <p className={
                    rec.deleteReason === "completed"
                      ? "badge badge-primary"
                      : "badge badge-success"
                  }
                  href={`/variables?key=${rec.id}&processDefinitionKey=${rec.processDefinitionKey}`}
                >
                  {(rec.deleteReason === null)? 'Pending' : 'Completed' }
                </p>
              ),
              startTime: `${format(new Date(rec.startTime), "d MMM, yyyy").toString()}`,
              endTime:
                  rec.endTime === null
                    ? ""
                    :`${format(new Date(rec.endTime), "d MMM, yyyy").toString()}`,
                  //   duration: `${Math.round(
                  // rec.duration / 600000,
                  duration: `${Math.floor(rec.duration / 3600000)}h ${Math.floor((rec.duration % 3600000) / 60000)}m ${Math.floor((rec.duration % 60000) / 1000)}s`,
                claimTask: (rec.deleteReason === null || rec.assignee === null) ? (
                  <a
                    className="blue-text"
                    onClick={() => claimTask(rec.id, rec.assignee)}

                  >
                   <img src="./claim.svg" style={{ height: '2rem', width: '2rem', padding: '0.2rem' }} />
                  </a>
                  ) : (<p></p>) ,
              });
        });
        var d = {
          columns: cols,
          rows: r,
        };
          setData(d); // (1) write data to state   
      });
    
  }
    return (
    
        <div>
            <MainHeader />
           <>
           <div className="flex-ct pt-4 p-3" style={{marginTop : '-2rem'}}>
            
              <h4 className="">{query.taskName}</h4>
             
              <div>
                <strong>
                  &copy; Powered by <img src='./chime-icon.png' width={20} height={20}/> Chime
                </strong>
              </div>
        </div>
        <hr className="hr hr-blurry" />
          {/* <MDBNavbar color='white p-1' expand='md' scrolling >
            <MDBNavbarBrand href='/' className='py-0 font-weight-bold'> */}
              {/* <img src="./udbhata.png" style={{ height: '2.5rem', width: '5rem', padding: '0.1rem', marginLeft: '10px' }} /> */}
            {/* </MDBNavbarBrand>
            <h4 className='ml-5'>Form Details </h4>
          </MDBNavbar> */}

        <div className="column right" style={{marginLeft : '2rem'}}>
        <form id="myForm"className="m-3"></form>
        </div> 
      
        <div className="m-3 p-3">
        

            {/* <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={25} pagesAmount={4} data={data} pagingTop            
                searchTop
                searchBottom={false}
                exportToCSV
                theadColor="grey lighter-1"
                striped 
                small/>           */}
        </div>

        </>
       
        </div>
        
        );
}
