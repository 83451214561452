import {getEngineRestUrl} from './utils';
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import {  MDBNavbar,
    MDBNavbarBrand
  } from 'mdbreact';
//import "./styles.css";
import './TaskList.css';

export default function LoadExcel() {
  const [file, setFile] = useState(null);

  const fileHandler = e => {
    let fileObj = e.target.files[0];
    console.log(fileObj);

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setFile({
          cols: resp.cols,
          rows: resp.rows
        });
      }
    });
  };

  return (
    <>
    <MDBNavbar color="white p-1">
      <MDBNavbarBrand href="/" className="p-5 font-weight-bold">
        <img
          src="./udbhata.png"
          style={{
            height: "2.5rem",
            width: "5rem",
            padding: "0.1rem",
            marginLeft: "10px",
          }}
        />
      </MDBNavbarBrand>
      <h4 className="ml-5"></h4>
      {/* <h4 className="ml-5">Task Details <span  className={sessionStorage.getItem('dashbrdTitle') !== "" ? "visible" : "invisible"}>for {sessionStorage.getItem('dashbrdTitle')}</span> </h4> */}
    </MDBNavbar>
    <div  id="viewExcel" className="m-1 p-1">
      <input type="file" onChange={fileHandler} />
      {file && (
        <OutTable
          data={file.rows}
          columns={file.cols}
          tableClassName="table"
          tableHeaderRowClass="heading"
        />
      )}
    </div>
    </>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);


// class LoadExcel extends Component {
//     fileHandler = (event) => {
//         let fileObj = event.target.files[0];
    
//         //just pass the fileObj as parameter
//         ExcelRenderer(fileObj, (err, resp) => {
//           if(err){
//             console.log(err);            
//           }
//           else{
//             this.setState({
//               cols: resp.cols,
//               rows: resp.rows
//             });
//           }
//         });               
    
//       }

//     render = () => {
//         return(
//             <div id="viewExcel" style={{ width: '90%', height: '98vh', float: 'left' }}>
//                 <input type="file" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
//                 <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
//             </div>
//         )
//     }
// }

// export default LoadExcel;
