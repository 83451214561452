import React, {useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import queryString from 'query-string';
import { react } from '@babel/types';
import {  MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBCollapse, MDBNavItem, MDBContainer,MDBInput, MDBBtn} from 'mdbreact';
//import { LaptopWindows } from '@material-ui/icons';
import './Taskform.css';
import {createBrowserHistory} from 'history';
import {getEngineRestUrl, getEngineRestUri} from './utils';
import MainHeader from '../MainHeader';
export const history = createBrowserHistory({forceRefresh:true})

 export default function TaskForm(props) {
  var CamSDK = window.CamSDK;
  //let history = useHistory();
  const [query, setQuery] = React.useState({});
  //const [formUI, setFormUI] = React.useState({});
  const [hasTaskPerm, setHasTaskPerm] = React.useState();
  const [isAdmin, setIsAdmin] = React.useState();
  const isAdminUser = sessionStorage.getItem('IsAdmin');
  const userId = sessionStorage.getItem('userid');
   //headers: {'Access-Control-Allow-Origin': '*'},
// 'http://chime.pragmatiqinc.com/'
    const clientObj = {
      mock: false,
      apiUri: getEngineRestUri()
    }
    var camClient = new CamSDK.Client(clientObj);
  var taskService = new camClient.resource('task');
  useEffect( () => {
      let param = queryString.parse(props.location.search);
      setQuery(param);
      setIsAdmin(isAdminUser);
      checkUserAccess(param, userId, isAdminUser)
  }, [])
  function showForm(param,hasAdminAccess,access) {
      // loads the the task form
      if(param.key)
      {
        loadTaskForm(param.key, function(err, camForm) {
          if (err) {
            throw err;
          }
            var $submitBtn = $('<button type="submit" class="cust-color btn form-btn">Submit</button>').click(function (e) {
              camForm.submit(function (err) {
                //debugger;
                if (err) {
                  throw err;
                }
                // clear the form
                //$('#myForm').html('');
              });  
              e.preventDefault();
              history.push('/procdetails?key=' + sessionStorage.getItem('processInstanceId'));
              //              // window.location.href = '/procdetails?key=' + sessionStorage.getItem('processInstanceId');
              // useHistory().push('/procdetails?key=' + sessionStorage.getItem('processInstanceId'));
            });
            // var $saveBtn = $('<button type="save" class="form-btn">Save</button>').click(function (e) {
            //   camForm.save(function (err) {
            //     //debugger;
            //     if (err) {
            //       throw err;
            //     }
            //     // clear the form
            //     //$('#myForm').html('');
            //   });  
            //   e.preventDefault();
            //    history.push('/procdetails?key=' + sessionStorage.getItem('processInstanceId'));
            //   //              // window.location.href = '/procdetails?key=' + sessionStorage.getItem('processInstanceId');
            //   // useHistory().push('/procdetails?key=' + sessionStorage.getItem('processInstanceId'));
            // });
            
            //console.log(`===========>>>>> hasAdminAccess=${hasAdminAccess}; access=${access}`);
            // if ((hasAdminAccess && hasAdminAccess === 'true') || (access && access === 'true'))
            if (hasAdminAccess === 'true' || access === 'true' || hasAdminAccess === true || access === true)
            {
              //console.log("=====>>> Inside if condition...");
              camForm.containerElement.append($submitBtn);  
              // camForm.containerElement.append($saveBtn);             
            }
        });
      }
    }
    function loadTaskForm(taskId, callback) {
      // loads the task form using the task ID provided
      taskService.form(taskId, function(err, taskFormInfo) {
        if(taskFormInfo!=null)
        {
          var url = (taskFormInfo.contextPath === null) ? taskFormInfo.key.replace('embedded:deployment:', '/embedded-forms/forms/') : taskFormInfo.key.replace('embedded:app:', taskFormInfo.contextPath + '/');
          taskFormInfo.contextPath = (taskFormInfo.contextPath !== null) ? taskFormInfo.contextPath : '/embedded-forms/';
          url = getEngineRestUri() + url;
          new CamSDK.Form({
            client: camClient,
            formUrl: url,
            taskId: taskId,
            containerElement: $('#myForm'),
            // continue the logic with the callback
            done: callback
          });
        }
        else
        {
          // var url  = 'http://chime.pragmatiqinc.com/embedded-forms/forms/initiation.html';
          // new CamSDK.Form({
          //   client: camClient,
          //   formUrl: url,
          //   // the process definition ID
          //   processDefinitionId: 'submissionartwork:2:feff1e45-fb5b-11eb-a02a-e670b83fd36f',
          //   containerElement: $('#myForm'),
    
          //   // continue the logic with the callback
          //   done: callback
          // });
        }
      });
    }
  function checkUserAccess(param,userId, isAdminUser)
  {
    let access ;
    //http://chime.pragmatiqinc.com/engine-rest/history/task?taskId=096fec89-325d-11ec-8d12-e670b83fd36f&taskInvolvedUser=mary
    let fetchAPI = (`${getEngineRestUrl()}/history/task?taskId=${param.key}&taskInvolvedUser=${userId}`);
    fetch(fetchAPI)
      .then((res) => res.json())
      .then((userTaskAccess) => {
       // console.log(userTaskAccess.length);
   
        if(userTaskAccess.length > 0)
          access = true;
        else
          access = false;

          setHasTaskPerm(access);
          showForm(param,isAdminUser,access);
      });   
  }
  function claimTask(taskId)
  {
    let user = document.getElementById('userId').value;
      if(user==='' || user === undefined || user === null)
        user=null;
        var userdata = {
          userId: user }
        if(user !== null)
        {
        fetch(`${getEngineRestUrl()}/task/${taskId}/assignee`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userdata)
        })
        .then(res => {
          // console.log('status- ' +res.status)
          if(res.status === 204)
          {
          document.getElementById('userId').value = '';
          history.push('/procdetails?key=' + sessionStorage.getItem('processInstanceId'));
          }
          else
          {
            alert('Error occured')
          }
        })
        .catch((e) => {
          alert('Error occured while reassigning.'); 
          //console.log(e);
        })
        }
        else
        {
          alert('Please enter userId to reassign');
        }
  }
 return (
<div>
  <MainHeader />
         <MDBNavbar color="white p-1" expand="md" scrolling style={{marginTop:'2rem'}}>
        {/* <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
          <img
            src="./udbhata.png"
            style={{
              height: "2.5rem",
              width: "5rem",
              padding: "0.1rem",
              marginLeft: "10px",
            }}
          />
        </MDBNavbarBrand> */}
        
        <h4>{query.name}</h4> 
        
        {/* console.log({query.name}) */}
        <MDBNavbarNav right style={{padding:'0.4rem'}}>
        <MDBNavItem>

        <div className="d-flex flex-row justify-content-between align-items-center pt-4 p-3" style={{marginTop : '-2rem'}}>
         
      <h4 className="">{query.name}</h4>      
              <div>
                <strong>
                  &copy; Powered by <img src='./chime-icon.png' width={20} height={20}/> Chime
                </strong>
              </div>
      </div>
        <hr className="hr hr-blurry" />  
      <div>
        <span style={{visibility : (isAdmin || hasTaskPerm) ? 'visible' : 'hidden'}}>
        <MDBInput type='text' id='userId' label='UserId' style={{height:'2rem', padding:'0.2rem'}} outline />
        <MDBBtn id="claim" color="white" style={{ height: '2rem', width: '2rem', padding:'0.2rem'}} onClick={() => claimTask(query.key)}>
        <img src="./reassign.svg" style={{ height: '2rem', width: '2rem',}} />
        </MDBBtn>
        </span>
        </div>
        </MDBNavItem>
  </MDBNavbarNav>
      </MDBNavbar>
    <div className="m-1 p-1">
    <MDBContainer style={{marginTop: '1rem'}} >
    <form id="myForm"></form>
    </MDBContainer>
    </div>    
    {/* <div className="m-3 p-3" dangerouslySetInnerHTML={{__html: formUI}} /> */}
    </div>
 );
}
