import React from "react";
import { MDBContainer, MDBIframe } from "mdbreact";
import MainHeader from "../MainHeader";

const Cockpit = () => {
  return (
    <>
    <MainHeader/>
    <MDBContainer style={{width: '100%',height: '100vh', margin: 0, padding: 0}}>
      <iframe style={{margin: 0, padding: 0, top: 0, width: '1280px', height: '100%'}} src="http://chime.pragmatiqinc.com/camunda/app/cockpit" />
    </MDBContainer>
    </>
  );
}

export default Cockpit;