import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import queryString from "query-string";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBBtn,
  MDBIcon
} from "mdbreact";
import { format } from "date-fns";
import {getEngineRestUrl} from './utils';
import {useHistory} from 'react-router-dom';

import { react } from '@babel/types';
import {  MDBContainer, MDBInput} from 'mdbreact';
import dashboardSettings from '../mockdata/dashboardSettings.json';
import './Taskform.css';
import { formatDistance, subDays, parseISO } from 'date-fns';
import {createBrowserHistory} from 'history';
import {getEngineRestUri} from './utils';
import MainHeader from "../MainHeader";
export const history = createBrowserHistory({forceRefresh:true})
export default function ProcessDetails(props) {
  var cols = [
    
    // {
    //   label: "Claim",
    //   field: "claimTask",
    //   width: 150,
    // },
    {
      label: "",
      field: "id",
      width: 150,
    },    
    {
      label: "Activity",
      field: "name",
      width: 270,
    },
    {
      label: "Description",
      field: "description",
      width: 270,
    },
    {
      label: "Status",
      field: "deleteReason",
      width: 270,
    },
    // {
    //   label: "Owner",
    //   field: "owner",
    //   width: 250,
    // },
    // {
    //   label: "Assignee ",
    //   field: "assignee",
    //   width: 100,
    // },
    {
      label: "Started On",
      sort: "desc",
      field: "startTime",
      width: 150,
    },
    {
      label: "Ended On",
      field: "endTime",
      width: 100,
    },
    {
      label: "Duration",
      field: "duration",
      width: 100,
    },
    /* {
      label: "Priority",
      field: "priority",
      width: 100,
    }, */
    // {
    //   label: "Due",
    //   field: "due",
    //   width: 100,
    // },
    // {
    //   label: "Followup",
    //   field: "followUp",
    //   width: 100,
    // },
  ];
  const [query, setQuery] = React.useState({});
  const [data, setData] = React.useState({ columns: [], rows: [] });
  var CamSDK = window.CamSDK;
  const [userTasks, setUserTasks] = React.useState([]);
  const [isAdmin, setIsAdmin] = React.useState();
  const userId = sessionStorage.getItem('userid');
  const clientObj = {
    mock: false,
    apiUri: getEngineRestUri()
  }//'http://chime.pragmatiqinc.com/'
  //console.log("====>>"+JSON.stringify(clientObj));
  var camClient = new CamSDK.Client(clientObj);
  var taskService = new camClient.resource('task');
  useEffect( () => {
    function fetchData()
    {
      localStorage.removeItem('liTaskId');
      const isAdminUser = sessionStorage.getItem('IsAdmin');
      setIsAdmin(isAdminUser);
      loadTasks(isAdminUser);
    }
    fetchData();
  }, [])
  
  function loadTasks(isAdmin) {
   // console.log('user check', isAdmin, userId)
    // fetch the list of available tasks
    let filterParams;
    //if(!sessionStorage.getItem('IsAdmin'))
    if(isAdmin == 'true')
      filterParams = {};
    else
      filterParams = {assignee: userId};
     // console.log('filter',JSON.stringify(filterParams))
    taskService.list(filterParams, function (err, results) {
      
      if (err) {
        throw err;
      }
      let taskHdrs = dashboardSettings.map(({processDefinition,taskListHdr}) => ({processDefinition,taskListHdr}));
      const data = results._embedded.task;
     // console.log('data here', data)
      const resultData = [];
      for(let i=0; i< data.length; i++){
          resultData.push({
          id: data[i].id,
          processInstanceId: data[i].processInstanceId,
          name: data[i].name,
          createdDate: data[i].created,
          processName: data[i]._embedded.processDefinition[0].name,
          processKey: data[i]._embedded.processDefinition[0].key,
          assignee: data[i].assignee,
          formattedDate:'Created ' + formatDistance(new Date(data[i].created),new Date(),{ addSuffix: true})
        });
      }        //let procIds =resultData.map(rd => rd.procId).join(',');
      if(resultData.length > 0)
      {
       // console.log("prociddetails",procIds);

        resultData.forEach(rec => {
        const taskListHdrRec = taskHdrs.find(findR => findR.processDefinition === rec.processKey);
        if(taskListHdrRec)
          rec['taskListHdr'] = taskListHdrRec.taskListHdr;
        });
        let procIds = getCommaSeperatedStringFromJsonArray(resultData, 'processInstanceId');
        fetchProcVariables(procIds, resultData);
      }
    });
  }
 
  function fetchProcVariables(procIds, resultData)
  {    
    // console.log('query checking', query);
    
    // console.log(sessionStorage.getItem('processInstanceId'))
    // console.log("--===-", resultData)
    // console.log("procid",procIds)
    // if(procIds == rec.id){
    //   console.log("taskid",rec.id)
    // }
   
    let procId = procIds.split(',')[0]
    fetch(`${getEngineRestUrl()}/history/variable-instance?processInstanceIdIn=${procId}&variableNameLike=nrO%`)
    .then((res) => res.json())
    .then((procVars) => { 
        resultData.forEach(res => {
          if(!(res.hasOwnProperty('taskListHdrVal')))
          {
            
            const taskListHdrValRec = procVars.find(rec => rec.processInstanceId === res.processInstanceId && rec.name === res.taskListHdr);
            if(taskListHdrValRec)
              res['taskListHdrVal'] = taskListHdrValRec.value;
          }
        });
        setUserTasks(resultData);
    });
  }
  function getCommaSeperatedStringFromJsonArray(jsonArray, mkey)
  {
    let uniqueBymkeyCommaSeperatedString = [...new Map(jsonArray.map(item => [item[mkey], item])).values()].map(item => item[mkey]).join(',');
    return uniqueBymkeyCommaSeperatedString;
  }
  useEffect(() => {
    async function fetchData() {
    let param = queryString.parse(props.location.search);
    //sessionStorage.removeItem('processInstanceId');
    sessionStorage.setItem('processInstanceId', param.key);
    setQuery(param);
    await getProcessList(param);
    }
    fetchData();
  }, []);
  

  async function getProcessList(param) {
   // console.log("key = ", param.key);
    fetch(`${getEngineRestUrl()}/history/task?processInstanceId=${param.key}`)
      .then((res) => res.json())
      .then((recordset) => {

        var r = [];
        recordset.map((rec) => {
          //console.log('deletereason', rec.deleteReason);
          r.push({
            ...rec,
            id: rec.deleteReason === 'completed' ?
                <a className="blue-text" href={`/variables?key=${param.key}&taskName=${rec.processDefinitionKey}`}>
                View
              </a> :
              <a className="blue-text" href={`/variables?key=${param.key}&taskId=${rec.id}&taskName=${rec.processDefinitionKey}`}>
                Edit 
              </a>
          
        
    //     <a className="blue-text" href={`/tasks`}>
    //     View
    //   </a>
    ,
   
              // rec.deleteReason === null ? (
              //   <a className="blue-text" href={"/form?key=" + rec.id + "&name=" + rec.name}>
              //     View
              //   </a>
              // ) : (
              //   <a className="blue-text" href={"/variables?key=" + param.key}>
              //     View
              //   </a>
              // ),
              deleteReason: (
                
                <p className={
                  
                    rec.deleteReason === "completed"
                      ? "cust-badge px-3 py-2 badge badge-primary "
                      : "cust-badge px-3 py-2 badge badge-success "
                  }
                  href={`/variables?key=${rec.id}&processDefinitionKey=${rec.processDefinitionKey}`}
                >
                  {(rec.deleteReason === null)? 'Pending' : 'Completed' }
                </p>
              ),
              startTime: `${format(new Date(rec.startTime), "d MMM, yyyy  HH:mm:ss").toString()}`,
              endTime:
                  rec.endTime === null
                    ? ""
                    :`${format(new Date(rec.endTime), "d MMM, yyyy  HH:mm:ss").toString()}`,
                  //   duration: `${Math.round(
                  // rec.duration / 60000,
                  duration: `${Math.floor(rec.duration / 3600000)}h ${Math.floor((rec.duration % 3600000) / 60000)}m ${Math.floor((rec.duration % 60000) / 1000)}s`,
                // claimTask: (rec.deleteReason === null || rec.assignee === null) ? (
                //   <a
                //     className="blue-text"
                //     onClick={() => claimTask(rec.id, rec.assignee)}

                //   >
                //    <img src="./claim.svg" style={{ height: '2rem', width: '2rem', padding: '0.2rem' }} />
                //   </a>
                //   ) : (<p></p>) ,
              });
        });
        var d = {
          columns: cols,
          rows: r,
        };
          setData(d); // (1) write data to state   
      });
  }
  return (
    <div>
      <MainHeader />
      {/* <MDBNavbar color="white p-1" expand="md" scrolling>
     
      <MDBNavbarNav left style={{marginTop:'3rem', marginLeft:'3rem'}}><h5> <b>Tasks</b></h5> </MDBNavbarNav> */}
      {/* <MDBNavbarNav right>
            <MDBNavItem style={{marginTop:'2rem'}}>
          <div>
            <span style={{visibility : userTasks.length > 0 ? 'visible' : 'hidden'}}>
            <MDBInput type='text' id='userId' label='UserId' outline />
            <MDBBtn id="claim" color="white" style={{ height: '2rem', width: '2rem', padding: '0.2rem', verticalAlign: 'top'}} className='py-0 font-weight-bold' onClick={() => claimTask()}>
            <img src="./reassign.svg" style={{ height: '2rem', width: '2rem', padding: '0.2rem'}} />
            </MDBBtn>
            </span>
            </div></MDBNavItem>
      </MDBNavbarNav> */}
      {/* </MDBNavbar> */}

      <div className="d-flex flex-row justify-content-between align-items-center p-3" style={{marginTop : '-2rem', padding: 0}}>
      <h3 className="">Tasks</h3>      
              <div>
                <strong>
                  &copy; Powered by <img src='./chime-icon.png' width={20} height={20}/> Chime
                </strong>
              </div>
      </div>
        <hr className="hr hr-blurry" />  


      {/* <MDBContainer className='layout m-0 p-0' style={{marginTop: '1rem', visibility: userTasks.length > 0 ? "visible" : "hidden",}} > */}
      <div className="m-3 p-3">
      
        <MDBDataTableV5
          hover
          entriesOptions={[15, 25, 35]}
          entries={10}
          pagesAmount={5}
          data={data}
          pagingTop
          searchTop
          searchBottom={false}
          exportToCSV
          //theadColor="grey lighter-1"
          className="custom-header cust-col"
          striped
          small
        />
      </div>
      
      {/* </MDBContainer> */}
      <span style={{fontSize: userTasks.length === 0 ? '0px' :'25px', visibility: userTasks.length === 0 ? "visible" : "hidden"}}><b>No Pending Tasks!!</b></span>
      {/* <div className="m-3 p-3" dangerouslySetInnerHTML={{__html: formUI}} /> */}
    </div>
    
     );
}
