import React, {useEffect, useState, useRef} from 'react';
import imageToBase64 from 'image-to-base64/browser'; 
// import sourceimg from '../assets/source.jpg';
// import destimg from '../assets/dest.jpg';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Resemble from 'resemblejs';  

var images = {
        before: "../assets/source.jpg", after: "../assets/dest.jpg"
    } 

const Proofing = () => {
    const [source, SetSource] = useState(null); 
    const [dest, SetDest] = useState(null); 
    const [diff, SetDiff] = useState(null); 

    const sourceCanvasRef = useRef(); 
    const destCanvasRef = useRef(); 

    useEffect(() => {
        loadSource();
        loadDest();
    }, []);

    function loadSource() {
        imageToBase64(sourceimg) // Path to the image
        .then(
            (response) => {
                response = 'data:image/jpg;base64, ' + response; 
                // console.log('Response=', response); // "cGF0aC90by9maWxlLmpwZw=="
                SetSource(response);
            }
        )
        .catch(
            (error) => {
                console.log(error); // Logs an error if there was one
            }
        )
    }

    function loadDest() {
        imageToBase64(destimg) // Path to the image
        .then(
            (response) => {
                response = 'data:image/jpg;base64, ' + response; 
                SetDest(response);
                compare();
            }
        )
        .catch(
            (error) => {
                console.log(error); // Logs an error if there was one
            }
        )
    }

    function compare() {

        Resemble(source).compareTo(dest).onComplete( (data) => {
            SetDiff(data.getImageDataUrl())
        })

    }

    return(
        <MDBContainer>
        <MDBRow>
            <MDBCol size='sm' className='col-example'>
                <img src={source} alt="source" width="80%"/>
            </MDBCol>
            <MDBCol size='sm' className='col-example'>
                <img src={dest} alt="dest" width="80%"/>
            </MDBCol>
            <MDBCol size='sm' className='col-example'>
                <img src={diff} alt="diff" width="80%"/>
            </MDBCol>
        </MDBRow>
        </MDBContainer>
    )

} 

export default Proofing;