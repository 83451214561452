import React, { Component } from 'react';
import './App.css';
import { useHistory } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBFooter,
  MDBNavLink,
  MDBTooltip,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactComponent as Logo } from './assets/logo.svg';
import Routes from './Routes';
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import {createBrowserHistory} from 'history';
export const history = createBrowserHistory({forceRefresh:true})

class LogoHeader extends Component {

  render() {
 
    return (
      <Router>
        <div className='flyout'>
          <MDBNavbar className='Navcolor' color='p-1' dark expand='md' fixed='top' scrolling >
          
            <MDBNavbarNav left>
              <MDBNavItem>
                 {/* <MDBBtn href='https://udbhata.com/' target='_blank' rel='noopener noreferrer' gradient="aqua" style={{height: '2.3rem'}} className='py-0 font-weight-bold'> */}
                  <img src="./udbhata.png" style={{ height: '2rem', width: '6rem', padding: '0.2rem' }} />
                  {/* </MDBBtn> */}
                {/* </div> */}
              </MDBNavItem>
            </MDBNavbarNav>

            </MDBNavbar>

              
        </div>       
      </Router>
    );
  }
}

export default LogoHeader;
