import React, { useEffect } from "react";
import queryString from "query-string";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBBtn,
  MDBIcon,
  MDBDataTableV5, MDBProgress,
  MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter 
} from "mdbreact";
import { format } from "date-fns";
import {createBrowserHistory} from 'history';
import {getEngineRestUrl} from './utils';
export const history = createBrowserHistory({forceRefresh:true})
//import {useHistory} from 'react-router-dom'; 
import dashboardSettings from '../mockdata/dashboardSettings.json';
import MainHeader from "../MainHeader";
export default function ProcessSummary(props) {
  var cols = [
    // {
    //   label: "",
    //   field: "claimTask",
    //   width: 150,
    // },
    {
      label: "View",
      field: "id",
      width: 60,
      sort: "disabled",
    },
    {
      label: "Workflow",
      field: "startActivityId",
      width: 60,
      sort: "disabled",
    },
    
    // {
    //   label: "Initiated by",
    //   field: "startUserId",
    //   width: 50,
    // }
    // {
    //   label: "Status",
    //   field: "state",
    //   width: 100,
    // }
    
  ];
  // const d2 = {columns: [{label: 'first', field: 'field1', width: 100}], rows: [ {'field1': <a href="google.com">bharat</a>}]}
  const [query, setQuery] = React.useState({});
  const [dData, setData] = React.useState({ columns: [], rows: [] });
  const [modifiedInstances, setModData] = React.useState([]);
   const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [hasInitiatePerm, setHasInitiatePerm] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState([]);
  const isAdminUser = sessionStorage.getItem('IsAdmin');
  const userId = sessionStorage.getItem('userid');
  const [refreshDashboard, setRefreshDashboard] = React.useState(false);
  useEffect(() => {
    async function fetchData() {
      let param = queryString.parse(props.location.search);
      let superProcId= (param.superPId !== undefined) ? param.superPId : "";
      let data= dashboardSettings.filter( element => element.processDefinition === param.key);
      setQuery(param);
      setIsAdmin(isAdminUser);
      //console.log('param>> ' + JSON.stringify(param));
      await checkInstantiatePerm(param.key,userId);
      await getProcessList(param.key, data, superProcId);
    }
    fetchData();
  }, [refreshDashboard]);
  
  function getDashBoardCols(data)
  {
    let dashboardColFields = [];
    if(data[0]!== undefined)
    {
    dashboardColFields = data[0].dashboardFields;
    dashboardColFields.map((dCol) => {
      if((cols.findIndex(col => col.field === dCol.field) === -1))
      {
        cols.push({
          label: dCol.label,
          field: dCol.field,
          width: dCol.width
        });
      }
    });}
    cols.push({
      label: "Start Time",
      field: "startTime",
      sort: "desc",
      width: 110,
    });
    cols.push({
      label: "End Time",
      field: "endTime",
      width: 110,
    });
    // cols.push({
    //   label: "Duration",
    //   field: "durationInMillis",
    //   width: 50,
    // });
    cols.push({
      label:"Running Status",
      field: "state",
      width:50,
      attributes:{
        'class':'cust-col'
      }
    
    })
    return dashboardColFields;
  }
   const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  async function getDashBoardData(pKey, superProcId)
  {
    let fetchAPI = (superProcId !== undefined && superProcId !== "") ? (`${getEngineRestUrl()}/history/process-instance?processDefinitionKey=${pKey}&superProcessInstanceId=${superProcId}`) : (`${getEngineRestUrl()}/history/process-instance?processDefinitionKey=${pKey}`);
    // if(isAdminUser === 'false' && userId)
    // {fetchAPI += "&startedBy=" + userId;}

    const res = await fetch(fetchAPI);
    const procInstances = await res.json();
    //sorting
     procInstances.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

    return procInstances;
  }
  async function getSubProcesses(pKey)
  {
    const subProcRes = await fetch(`${getEngineRestUrl()}/history/process-instance?superProcessInstanceId=${pKey}`);
    const subProcData = await subProcRes.json();
    //history.push('/procsummary?name=' + processDefinitionName + '&key=' + processDefinitionKey + '&pId=' + processDefinitionId + "&procType=superP")

    let uniqueProcs = [...new Map(subProcData.map((x) => [x['processDefinitionKey'], x])).values()]
      .map(({processDefinitionId,processDefinitionKey,processDefinitionName}) => 
      ({processDefinitionId,processDefinitionKey,processDefinitionName}));
    for(let i=0; i<uniqueProcs.length; i++)
    {
        let rec=uniqueProcs[i];
        const count = subProcData.filter((obj) => obj.processDefinitionKey === rec["processDefinitionKey"]).length;
        rec["count"] = count;
    }
    return uniqueProcs;
  }
  async function populateDynamicColsData(procInstanceLst, dashboardFields, pKey)
  {
    let colsData = [];
    const result = [];
    let startVariableLike = "";
    let totalProcs = 0, completedProcs = 0;
    if(dashboardFields.length > 0)
      startVariableLike = dashboardSettings.filter(element => element.processDefinition === pKey)[0].startFormVariableLike;
    for(let i=0; i< procInstanceLst.length; i++){
    let rec = procInstanceLst[i];
    let uniqueSubProcs = await getSubProcesses(rec.id);
    if(uniqueSubProcs.length > 0)
    { //history.push('/procsummary?name=' + processDefinitionName + '&key=' + processDefinitionKey + '&pId=' + processDefinitionId + "&procType=superP")
      uniqueSubProcs.forEach((uProcRec)=> {
        if((cols.findIndex(col => col.field === uProcRec.processDefinitionKey) === -1)){
          cols.push({
            label: uProcRec.processDefinitionName,
            field: uProcRec.processDefinitionKey,
            width: 150,
            sort: "disabled",
          });
        }
        colsData.push({ColType: "Ref", ColRefTitle : "(" + uProcRec.count + ")", ColRefText:"",  ColName : uProcRec.processDefinitionKey, ColValue: "/procsummary?name=" + uProcRec.processDefinitionName + "&key=" + uProcRec.processDefinitionKey + "&pId=" + uProcRec.processDefinitionId + "&superPId=" + rec.id, ProcId : rec.id})
        rec[uProcRec.processDefinitionKey] = "/procsummary?name=" + uProcRec.processDefinitionName + "&key=" + uProcRec.processDefinitionKey + "&pId=" + uProcRec.processDefinitionId + "&superPId=" + rec.id;
      });
    }
    if(dashboardFields.length > 0){
      let totInstances=0;
      let compInstances=0; 
    const dynamicColRes = await fetch(`${getEngineRestUrl()}/history/variable-instance?processInstanceId=${rec.id}&variableNameLike=${startVariableLike}%`);
    const dynamicColData = await dynamicColRes.json();
   // console.log(dynamicColData)
    dynamicColData.forEach((dynamicColRec) => {
      if(dynamicColRec.name==="nrOfInstances")
      {
        totInstances=dynamicColRec.value;
      }
      if(dynamicColRec.name==="nrOfCompletedInstances")
      {
        compInstances=dynamicColRec.value;
      }
      if(totInstances !== undefined && compInstances!==undefined)
      {
        if((colsData.findIndex(col => col.ColName === dynamicColRec.processDefinitionKey) === -1))
        {
          colsData.forEach((item,index)=>{
            if(item.ProcId === rec.id && item.ColType === "Ref")
            {
            colsData[index].ColRefTitle = compInstances + "/" + totInstances;
            colsData[index].ColRefText = compInstances/totInstances*100; 
            }
         })
         
        }
      }
      dashboardFields.forEach((dCol) => {
      if(dynamicColRec.name === dCol.field)
          {
            let colVal = (dynamicColRec.type === "Json") ? JSON.stringify(dynamicColRec.value) : dynamicColRec.value
            rec[dynamicColRec.name] = colVal;
            colsData.push({ColType: "String", ColRefTitle :"", ColRefText:"", ColName : dynamicColRec.name, ColValue : colVal, ProcId: rec.id});
          }
      });
    });
  
  }
    result.push(rec);
  }
  return {
    result,
    colsData
  };
  }
  async function checkInstantiatePerm(pKey, userId)
  {
    let fetchAPI = (`${getEngineRestUrl()}/process-definition?latestVersion=true&key=${pKey}&startableBy=${userId}`);
    // if(isAdminUser === 'false' && userId)
    // {fetchAPI += "&startedBy=" + userId;}  #{execution.processBusinessKey}
    const res = await fetch(fetchAPI);
    const procDefAccess = await res.json();
    setHasInitiatePerm(procDefAccess.length > 0 ? true : false);
    // console.log('fetch api',fetchAPI )
    // console.log('hasInitate',procDefAccess);
    //console.log("defs--" + JSON.stringify(procDefAccess) + " len-- " + procDefAccess.length);
    //http://chime.pragmatiqinc.com/engine-rest/process-definition?latestVersion=true&key=submissionartwork&startableBy=peter
  }
  async function getProcessList(pKey, data, superProcId)
  {
    let rs = [];
    let dashboardColFields = [];
    dashboardColFields = getDashBoardCols(data);
    const procInstances= await getDashBoardData(pKey, superProcId);
    const procIds = procInstances.map(rec => rec.id);
    let retVals = await populateDynamicColsData(procInstances, dashboardColFields, pKey);
    const modProcInstance = retVals.result;
    let rd = retVals.colsData;
    modProcInstance.forEach((rec,index) => {
     // console.log('duration', rec);
    rs.push({
      id: (
        <a className="blue-text" href={"/procdetails?key=" + rec.id}>
        <img src="./details.svg"  style={{ height: '2rem', width: '2rem', padding: '0.2rem' }} />
        </a>
      ),
      //startTime: `${new Date(rec.startTime).toLocaleString()}`,
    

      startTime: `${format(new Date(rec.startTime), "MMM d, yyyy  HH:mm:ss ").toString()}`,
      endTime:
        rec.endTime === null
          ? ""
          // : `${new Date(rec.endTime).toLocaleString()}`,
          :`${format(new Date(rec.endTime), "MMM d, yyyy  HH:mm:ss").toString()}`,
      // durationInMillis: `${Math.round(
      //   rec.durationInMillis / 60000,
      duration: `${Math.floor(rec.duration / 3600000)}h ${Math.floor((rec.duration % 3600000) / 60000)}m ${Math.floor((rec.duration % 60000) / 1000)}s`,
      state: (
        <span  className={rec.state === "ACTIVE" ? "cust-badge px-3 py-2 badge badge-success": "cust-badge px-3 py-2 badge badge-primary"}> {rec.state}</span>
        // <a
        //   className={
        //     rec.state === "ACTIVE"
        //       ? "badge badge-success"
        //       : "badge badge-primary"
        //   }
        //   href={"/variables?key=" + rec.id}
        // >
        //   {rec.state}
        // </a>
      ),
      startUserId: rec.startUserId,
      startActivityId: (
        <a
          className="blue-text"
          href={"/showdiagram?key=" + pKey}
        >
         <img src="./workflow.svg" style={{ height: '2rem', width: '2rem', padding: '0.2rem' }} />
        </a>
        ) ,
      });
    let rcd = rd.filter((rde) => rec.id.toString() === rde.ProcId.toString());
    // .map(({ColName,ColValue}) => ({ColName,ColValue}));
    
    rcd.forEach((e) =>{//  <img src={"./" + e.ColName + ".svg"} style={{ height: "2rem", width: "2rem", padding: "0.2rem" }}/>
        if(e.ColType === "Ref")
        { (rs[index][e.ColName] = (<a href={e.ColValue}><MDBProgress className="progressStyle" style={{height:"15px"}} value={e.ColRefText} color="success"></MDBProgress><span><b>{e.ColRefTitle}</b></span></a>
          ));
        }
        else
        { (rs[index][e.ColName] = e.ColValue);}
      }
    );
     modProcInstance.sort((a, b) => {
      const dateA = new Date(a.startTime);
      const dateB = new Date(b.startTime);
      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });

    let d = {
      columns: cols,
      rows: rs,
      };  
    setData(d)
    });
    
    
  }
  
  async function instantiateProcess(processDefinitionName, processDefinitionId, processDefinitionKey) {
 // console.log('instntiate',processDefinitionName, processDefinitionId, processDefinitionKey)
  const data = dashboardSettings.filter((element) => element.processDefinition === processDefinitionKey);
  const skipInitiate = data[0].skipInitiate;
 // console.log('hello', data[0]);
  
  if (skipInitiate) { //If the start form has form variables the below code may not work please handle form variables here ,,, Suggestion - (check dashboardsettings.json)
   // console.log("Skipping Initiation of Process");
    
     const response = await fetch(`${getEngineRestUrl()}/process-definition/key/${processDefinitionKey}/start`,  { method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }});
        const data = await response.json();
     //   console.log('data------------------',data, response.status)
        if(response.status == 200 ){
          setRefreshDashboard(!refreshDashboard)
        
        }
  
//   // /process-definition/key/{key}/start

  } else {
    await history.push("/initiateprocess?name=" + processDefinitionName + "&pId=" + processDefinitionId + "&key=" + processDefinitionKey);
  }
}

  return (
    <>
    <MainHeader />
      {/* <MDBNavbar color="white p-1" expand="md" scrolling> */}
        
      {/* <div className="flex-ct pt-4 p-3 ">
              <span> </span>
              
      </div> */}
      <div className="d-flex flex-row justify-content-between align-items-center pt-4 p-3" style={{marginTop : '-2rem'}}>
   
      <h4 className="">{query.name}</h4>      
                <div className="d-flex flex-row align-items-center">
                <span className='align-middle' style={{visibility : hasInitiatePerm ? 'visible' : 'hidden'}}>
            <MDBBtn  id="startProcess" className="custom-bg-color" style={{ backgroundColor: '#036667 !important' }} onClick={handleButtonClick}>Initiate - {query.name}</MDBBtn>
              <MDBModal isOpen={isModalOpen} toggle={closeModal}>
              <MDBModalHeader toggle={closeModal}>INITIATE - {query.name} </MDBModalHeader>
              <MDBModalBody>
                <p style={{fontSize: '0.9rem'}}>Please Click on Initiate Process Button to Instantiate a New Process</p>
              </MDBModalBody>
              <MDBModalFooter>
          <MDBBtn color="secondary" onClick={closeModal}>Close</MDBBtn>
          <MDBBtn
            color="primary"
            onClick={() => { instantiateProcess(query.name, query.pId, query.key);  closeModal() } }
          >
            Initiate Process
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
                </span>
                <div>
                <strong>
                  &copy; Powered by <img src='./chime-icon.png' width={20} height={20}/> Chime
                </strong>
                </div>
              {/* <MDBBtn className=''><MDBIcon far icon="copyright" />Powered by Chime</MDBBtn> */}
                </div>
        </div>
        <hr className="hr hr-blurry" />
        
        {/* <MDBNavbarNav right style={{padding:'0.4rem', marginTop:"1rem"}}>
                <MDBNavItem> */}
              <div>
                {/* <span style={{visibility : (query.superPId !== "" && query.superPId != undefined) ? 'hidden' : 'visible'}}> */}
                
                </div>
                {/* </MDBNavItem>
          </MDBNavbarNav> */}
      {/* </MDBNavbar> */}
      <div className="m-3 p-3">
        <MDBDataTableV5 
          hover
          entriesOptions={[10,15,20]}
          entries={10} style={{padding:'1rem'}}
          pagesAmount={6}
          data={dData}
          pagingTop
          searchTop
          searchBottom={false}
          exportToCSV
          // theadColor="grey lighter-1"
          className="custom-header cust-col"
          striped
        />
      </div>
    </>
  );
}
