import React, {useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import queryString from 'query-string';
import { react } from '@babel/types';
import {  MDBCol,MDBNavbar,MDBIcon, MDBNavbarBrand, MDBNavbarNav, MDBCollapse, MDBNavItem, MDBContainer, MDBBtn, MDBInput } from 'mdbreact';
import dashboardSettings from '../mockdata/dashboardSettings.json';
import './Taskform.css';
import { formatDistance, subDays, format, parseISO } from 'date-fns';
import {createBrowserHistory} from 'history';
import {getEngineRestUri, getEngineRestUrl} from './utils';
import MainHeader from '../MainHeader';
export const history = createBrowserHistory({forceRefresh:true})

 export default function Tasks(props) {
  var CamSDK = window.CamSDK;
  const [userTasks, setUserTasks] = React.useState([]);
// To search for Filtered Tasks
  const [searchTerm, setSearchTerm] = useState('');

  const [isAdmin, setIsAdmin] = React.useState();
  const userId = sessionStorage.getItem('userid');
  const clientObj = {
    mock: false,
    apiUri: getEngineRestUri()
  }//'http://chime.pragmatiqinc.com/'
  //console.log("====>>"+JSON.stringify(clientObj));
  var camClient = new CamSDK.Client(clientObj);
  var taskService = new camClient.resource('task');
  useEffect( () => {
    function fetchData()
    {
      localStorage.removeItem('liTaskId');
      const isAdminUser = sessionStorage.getItem('IsAdmin');
      setIsAdmin(isAdminUser);
      loadTasks(isAdminUser);
    }
    fetchData();
  }, [])
  
  function loadTasks(isAdmin) {
    // fetch the list of available tasks
    let filterParams;
    //if(!sessionStorage.getItem('IsAdmin'))
    if(isAdmin === 'true')
      filterParams = {};
    else
      filterParams = {assignee: userId};
    taskService.list(filterParams, function (err, results) {
      
      if (err) {
        throw err;
      }
      let taskHdrs = dashboardSettings.map(({processDefinition,taskListHdr}) => ({processDefinition,taskListHdr}));
      const data = results._embedded.task;
      const resultData = [];
      for(let i=0; i< data.length; i++){
          resultData.push({
          id: data[i].id,
          processInstanceId: data[i].processInstanceId,
          name: data[i].name,
          createdDate: data[i].created,
          processName: data[i]._embedded.processDefinition[0].name,
          processKey: data[i]._embedded.processDefinition[0].key,
          assignee: data[i].assignee,
          formattedDate:'Created ' + formatDistance(new Date(data[i].created),new Date(),{ addSuffix: true})
        });
      }        //let procIds =resultData.map(rd => rd.procId).join(',');
      if(resultData.length > 0)
      {
        resultData.forEach(rec => {
        const taskListHdrRec = taskHdrs.find(findR => findR.processDefinition === rec.processKey);
        if(taskListHdrRec)
          rec['taskListHdr'] = taskListHdrRec.taskListHdr;
        });
        let procIds = getCommaSeperatedStringFromJsonArray(resultData, 'processInstanceId');
        fetchProcVariables(procIds, resultData);
      }
    });
  }
  function fetchProcVariables(procIds, resultData)
  {    
    console.log("--===-", resultData)
    console.log("procid",procIds)
    let procId = procIds.split(',')[0]
    fetch(`${getEngineRestUrl()}/history/variable-instance?processInstanceIdIn=${procId}&variableNameLike=nrO%`)
    .then((res) => res.json())
    .then((procVars) => { 
        resultData.forEach(res => {
          if(!(res.hasOwnProperty('taskListHdrVal')))
          {
            const taskListHdrValRec = procVars.find(rec => rec.processInstanceId === res.processInstanceId && rec.name === res.taskListHdr);
            if(taskListHdrValRec)
              res['taskListHdrVal'] = taskListHdrValRec.value;
          }
        });
        setUserTasks(resultData);
    });
  }
  function getCommaSeperatedStringFromJsonArray(jsonArray, mkey)
  {
    let uniqueBymkeyCommaSeperatedString = [...new Map(jsonArray.map(item => [item[mkey], item])).values()].map(item => item[mkey]).join(',');
    return uniqueBymkeyCommaSeperatedString;
  }
  function showForm(taskid) {
    // loads the the task form
    document.getElementById('userId').focus();
    localStorage.setItem('liTaskId', taskid);
    loadTaskForm(taskid, function(err, camForm) {
      if (err) {
        throw err;
      }
        var $submitBtn = $('<button type="submit" class="cust-color btn form-btn">Submit</button>').click(function (e) {
          camForm.submit(function (err) {
            //debugger;
            if (err) {
              throw err;
            }
          });  
         // console.log('shf->  '+isAdmin)
          loadTasks(isAdmin);
          //clear the form
          $('#myForm').html('');
          e.preventDefault();
          //history.push('/procdetails?key=' + sessionStorage.getItem('processInstanceId'));
        });
        camForm.containerElement.append($submitBtn);             
    });
  }
  function loadTaskForm(taskId, callback) {
    // loads the task form using the task ID provided
    taskService.form(taskId, function(err, taskFormInfo) {
      if(taskFormInfo!=null)
      {
        var url = (taskFormInfo.contextPath === null) ? taskFormInfo.key.replace('embedded:deployment:', '/embedded-forms/forms/') : taskFormInfo.key.replace('embedded:app:', taskFormInfo.contextPath + '/');
        taskFormInfo.contextPath = (taskFormInfo.contextPath !== null) ? taskFormInfo.contextPath : '/embedded-forms/';
        url = getEngineRestUri() + url;
        new CamSDK.Form({
          client: camClient,
          formUrl: url,//'/embedded-forms/forms/initiation.html', //
          taskId: taskId,//'096fec89-325d-11ec-8d12-e670b83fd36f', //
          containerElement: $('#myForm'),
          // continue the logic with the callback
          done: callback
        });
      }
    });
  }
  function claimTask()
  {
    let user = document.getElementById('userId').value;
    let taskId = localStorage.getItem('liTaskId');
    if(taskId)
    {
      if(user==='' || user === undefined || user === null)
      {
        if(isAdmin === 'true')
          user = sessionStorage.getItem('userid');
        else
          user=null;
      }
        var userdata = {
          userId: user }
        if(user !== null)
        {
        //fetch('http://chime.pragmatiqinc.com/task/' +taskId +'/assignee', {
        fetch(`${getEngineRestUrl()}/task/${taskId}/assignee`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userdata)
        })
        .then(res => {
          if(res.status === 204)
          {
          // if(res){ res.json()}})
          loadTasks(isAdmin);
          localStorage.removeItem('liTaskId');
          $('#myForm').html('');
          document.getElementById('userId').value = '';
          }
          else
          {
            alert('Error occured')
          }
        })
        .catch((e) => {
          alert('Error occured while reassigning.'); 
          console.log(e);
        })
        }
        else
        {
          alert('Please enter userId to reassign');
        }
    } 
    else
    {alert('Please click the task to re-assign');}
  }

  const handleChange = (event) => {
    const input = event.target.value;
    setSearchTerm(input);
  }

  // const filteredTasks = userTasks.filter((task) =>
    
  //   task.name.toLowerCase().includes(searchTerm.toLowerCase())
    
  //   );

  const filteredTasks = userTasks.filter((task) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    return (
      task.name.toLowerCase().includes(searchTermLowerCase) ||
      task.formattedDate.toLowerCase().includes(searchTermLowerCase)
    );
  });


 return (
<div>
  <MainHeader />
  {/* <MDBNavbar color="white p-1" expand="md" scrolling> */}
 
  {/* <MDBNavbarNav left style={{marginTop:'3rem', marginLeft:'3rem'}}><h5> <b>Pending tasks</b></h5> </MDBNavbarNav> */}
  {/* <MDBNavbarNav>
        <MDBNavItem right style={{}}>
      <div>
        <span style={{visibility : userTasks.length > 0 ? 'visible' : 'hidden'}}>
        <MDBInput type='text' id='userId' label='UserId' outline/> */}
        {/* <MDBBtn id="claim" color="white" style={{ height: '2rem', width: '2rem', padding: '0.2rem', verticalAlign: 'top'}} className='py-0 font-weight-bold' onClick={() => claimTask()}>
        <img src="./reassign.svg" style={{ height: '2rem', width: '2rem', padding: '0.2rem'}} />
        </MDBBtn> */}
        {/* </span>
        </div></MDBNavItem>
  </MDBNavbarNav>
  </MDBNavbar> */}

  <div className="d-flex flex-row justify-content-between align-items-center pt-4 p-3" style={{marginTop : '-2rem'}}>
      <h4 className="">Pending Tasks</h4>      
                <div  id='userId' className="d-flex flex-row align-items-center">
                <span className='align-middle mr-2' style={{visibility : userTasks.length > 0 ? 'visible' : 'hidden'}}>
                   {/* <MDBInput type='text' id='userId' label='UserId' outline /> */}
                </span>
                <div>
                <strong>
                  &copy; Powered by <img src='./chime-icon.png' width={20} height={20}/> Chime
                </strong>
                </div>    
              
                </div>
        </div>
        <hr className="hr hr-blurry" />
        <div className="mt-3 mb-3" style={{display: 'flex', alignItems: 'center'}}>
          <MDBCol md="3">
          <input className="form-control" onChange={handleChange} type="text" value={searchTerm} placeholder="Search by Name or Created time" aria-label="Search" />
          </MDBCol>
        </div>
        <hr className="hr hr-blurry" />


  <MDBContainer className='layout m-0 p-0' style={{marginTop: '1rem', visibility: userTasks.length > 0 ? "visible" : "hidden",}} >
    <div className="column left">
      <ul id="tasks" style={{marginLeft: '0px'}}>
      {filteredTasks.map((task) => {
          return (
            <li key={task.id} id={task.id} onClick={() => showForm(task.id)}><div>
              <h6>{task.name}</h6>
              <table style={{width:'100%'}}><tbody>
              <tr>
                  <td colSpan='2'><b>{task.taskListHdrVal}</b></td>
                </tr>
                <tr>
                  <td colSpan='2' style={{fontSize:'13px'}}>{task.processName}</td>
                </tr>
                <tr>
                  <td style={{fontSize:'13px'}}>{task.formattedDate}</td>
                  <td align='right' style={{fontSize:'13px'}}>{task.assignee}</td>
                </tr>
              </tbody></table></div>
            </li>
          );
        })
      }
      </ul>
    </div>
    <div className="column right">
    <form id="myForm"className="m-3"></form>
    </div> 
  </MDBContainer>
  <span style={{fontSize: userTasks.length === 0 ? '0px' :'25px', visibility: userTasks.length === 0 ? "visible" : "hidden"}}><b>No Pending Tasks!!</b></span>
  {/* <div className="m-3 p-3" dangerouslySetInnerHTML={{__html: formUI}} /> */}
</div>
 );
}
