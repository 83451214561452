import React, { Component } from 'react';
import './App.css';
import { useHistory } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBFooter,
  MDBNavLink,
  MDBTooltip,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactComponent as Logo } from './assets/logo.svg';
import Routes from './Routes';
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import {createBrowserHistory} from 'history';
export const history = createBrowserHistory({forceRefresh:true})
import MainHeader from './MainHeader';
class App extends Component {

  render() {
   
    return (
      <>
      {/* <MainHeader /> */}
      <Router>
        <div className='flyout'>
          <main style={{ marginTop: '3rem' }}>
            <Routes />
          </main>
       
        </div>       
      </Router>
      </>
    );
  }
}

export default App;
