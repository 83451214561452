import React from 'react';
import { MDBBtn,MDBIcon, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import {useHistory} from 'react-router-dom';
import {  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
} from 'mdbreact'; 
// import './Modules.css';
import zIndex from '@material-ui/core/styles/zIndex';
import { VisibilityRounded } from '@material-ui/icons';
import {getEngineRestUrl} from './utils';
import MainHeader from '../MainHeader';
const Modules = () => {
  const [modules, setModules] = React.useState([]); 
  const [isLogged, setAuth] = React.useState([]); 
  const [isAdmin, setIsAdmin] = React.useState([]); 
  let history = useHistory();
  var colors = ['pink', 'purple', 'deep-purple', 'indigo', 'light-blue', 'cyan', 
        'dark-green', 'elegant', 'light-green', 'yellow', 'amber']
  React.useEffect(() => {
    const loggedUser = sessionStorage.getItem('userid');
    const isAdminUser = sessionStorage.getItem('IsAdmin');
    setAuth(((loggedUser) ? true : false));
    setIsAdmin(isAdminUser);
    if(!loggedUser)
    {
      history.push('/');
    }
    else
    {
      const restApi =`${getEngineRestUrl()}/process-definition?latestVersion=true&sortBy=version&sortOrder=asc&startableInTasklist=true`;
      fetch(restApi)
      .then((res) => res.json())
      .then((data) => {
          setModules(data); 
      });
     // console.log('module api',restApi);
    }
  }, []);

  function loadModule(name, key, id) {
      history.push('/procsummary?name=' + name + '&key=' + key + '&pId=' + id)
  }

  // function loadTaskList() {
  //     history.push("/tasklist");
  // }

  // function loadAdminModule() {
  //     history.push("/admin")
  // }

  // function loadCockpit() {
  //     history.push("/cockpit")
  // }

  return (
    <>
    <MainHeader />
      {/* <MDBNavbar color="white p-1" expand="md" scrolling>
        <MDBNavbarBrand href="/" className="font-weight-bold"> */}
          {/* <img
            src="./synthesis-logo.png"
            style={{
              height: "2.5rem",
              width: "5rem",
              padding: "0.1rem",
              marginLeft: "10px",
            }}
          /> */}
        {/* </MDBNavbarBrand> */}
        <div className="flex-ct pt-4 p-3" style={{marginTop : '-2rem'}}>
              <h4 className="">Choose a module to launch</h4>
              <div>
                <strong>
                  &copy; Powered by <img src='./chime-icon.png' width={20} height={20}/> Chime
                </strong>
              </div>
        </div>
        <hr className="hr hr-blurry" />
      {/* </MDBNavbar> */}
      <MDBContainer
        style={{
          marginTop: "6rem",
          height: "65vh",
          visibility: isLogged ? "visible" : "hidden",
        }}
      >
        <MDBRow style={{ marginTop: "2rem" }}>
          {modules.map((module, index) => (
            <MDBCol key={"colKey" + index.toString()}>
              {/* Added classname: Buttons to have fixed module button sizes */}
              <MDBBtn
                className='Buttons'
                color={colors[index]}
                key={"btnKey" + index.toString()}
                id={"btn" + index.toString()}
                // style={{ minHeight: "100px", minWidth: "220px", fontWeight: "bold", fontSize: "16px", color : colors[index+1], borderRadius : '15px' }}
              style={{ minHeight: "100px", minWidth: "220px"  }}
            onClick={() => loadModule(module.name, module.key, module.id)}
              >
                {module.name}
              </MDBBtn>
            </MDBCol>
          ))}
{/* 
          <MDBCol> 
        <MDBBtn className='Buttons' color="blue" key="tasks" id="tasks" style={{minHeight: '100px', minWidth: '220px'}} onClick={() => loadTaskList()}>
            Task List
        </MDBBtn>
        </MDBCol> */}
          {/* <MDBCol
            // style={{ visibility: isAdmin === 'true' ? "visible" : "hidden" }}
          >
            <MDBBtn
              className='Buttons'
              color="brown"
              key="settings"
              id="settings"
              style={{ minHeight: "100px", minWidth: "220px" }}
              onClick={() => loadAdminModule()}
            >
              Admin
            </MDBBtn>
          </MDBCol> */}
           {/* <MDBCol 
          //  style={{ visibility:  isAdmin === true ? "visible" : "hidden" }}
           >
            <MDBBtn
              className='Buttons'
              color="deep-orange"
              key="cockpit"
              id="cockpit"
              style={{ minHeight: "100px", minWidth: "220px" }}
              onClick={() => loadCockpit()}
            >
              Cockpit
            </MDBBtn>
          </MDBCol> */}
          {/*<MDBCol style={{ visibility:  isAdmin === true ? "visible" : "hidden" }}>
            <MDBBtn
              color="mdb-color"
              key="proofing"
              id="proofing"
              style={{ minHeight: "100px", minWidth: "220px" }}
              onClick={() => loadCockpit()}
            >
              Proofing
            </MDBBtn>
          </MDBCol> */}
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default Modules;