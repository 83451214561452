import React, { useState } from 'react';
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBIcon,
  MDBCard,
  MDBCardTitle,
  MDBCardImage,
  MDBCardText,
  MDBAnimation,
  MDBNavLink, 
  MDBInput, 
  MDBBtn,
  MDBAlert
} from 'mdbreact';
import './HomePage.css';
import {getEngineRestUri, getEngineRestUrl} from './chime/utils';
import LogoHeader from './LogoHeader';
class HomePage extends React.Component {
  scrollToTop = () => window.scrollTo(0, 0);
  state = {
    userID: '',
    isLogged: false
  };
  constructor(props) {
    super(props);
    this.getGroups = this.getGroups.bind(this); 
    
    this.login = this.login.bind(this); 
    }
  login() {
      var user = document.getElementById('userId').value; 
      var pass = document.getElementById('password').value;
      var data = {
        username: user, 
        password: pass
      }//'Access-Control-Allow-Origin': '*'
      // console.log(`rest url->  ${getEngineRestUrl()} rest uri-> ${getEngineRestUri()}`);
     // console.log(JSON.stringify(data,null,4));
      fetch(`${getEngineRestUrl()}/identity/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(res => res.json())
      .then(res => {
        console.log(JSON.stringify(res,null,4));
        if (res.authenticated)
            { 
              sessionStorage.setItem('userid', res.authenticatedUser); 
              this.setState({ userID: res.authenticatedUser});
              this.setState({isLogged:true});
              this.getGroups();
            }
            else 
            {
              alert('Invalid username and password. Please try again.');
            }
      })
      .catch( (e) => {
          alert('Error occured while authorizing. Contact administrator.'); 
          console.log(e);
      });
  }

  getGroups() {
      const USER_ID = sessionStorage.getItem('userid');
      fetch(`${getEngineRestUrl()}/identity/groups?userId=${USER_ID}`)
      .then(res => res.json())
      .then(res => {
              if (res.groups.filter((ele) => ele.id === "camunda-admin").length == 1)
                sessionStorage.setItem("IsAdmin", true);
              else 
                sessionStorage.setItem("IsAdmin", false);
              let grpNames = res.groups.map(val => val.id).join(',');
              sessionStorage.setItem('groups', grpNames); 
              this.props.history.replace('/modules'); 
             // console.log('grps- ' + sessionStorage.getItem('groups') + ' ' + JSON.stringify(res.groups) + ' isAdmin- ' + sessionStorage.getItem('IsAdmin'));
              //this.props.history.replace('/tasks'); 
      })
      .catch( (e) => {
          alert('Error occured while authorizing. Contact administrator.'); 
          console.log(e);
      });
  }
  async loadSettings()
  {
    if(!sessionStorage.getItem('engineRest'))
    {
      let fetchAPI;
      
      //console.log(`environment = ${process.env.REACT_APP_chimeEnv}`)
      if(process.env.REACT_APP_chimeEnv === 'dev'){//for dev env
        fetchAPI = 'http://localhost:3300/settings/';
      } else {// for prod env
        fetchAPI = 'http://chime.pragmatiqinc.com/settings/';
      }

      const res = await fetch(fetchAPI);
      const appsettings = await res.json();
      //console.log('fetchAPI ->'+fetchAPI+'; rest-> ' + appsettings.ENGINE_REST + '  --uri--  ' + appsettings.ENGINE_REST_URI);
      sessionStorage.setItem('engineRest', appsettings.ENGINE_REST);
      sessionStorage.setItem('engineRestUri', appsettings.ENGINE_REST_URI);
    }
  }
  async componentDidMount() {
    await this.loadSettings();
  }
  render() {
    return (
      <>
     
          <LogoHeader />

        <MDBEdgeHeader color='teal darken-3' className='sectionPage' />

        <div className='mt-3 mb-5'>
          <MDBFreeBird>
            <MDBRow>
              <MDBCol
                md='10'
                className='mx-auto float-none white z-depth-1 py-2 px-2'
              >
                <MDBCardBody className='text-center'>
                  <h2 className='h2-responsive mb-4'>
                    <strong className='font-weight-bold'>
                      {/* <img
                        src='./synthesis-logo.png'
                        alt='synthesis-logo.png'
                        className='pr-2'
                      /> */}
                      {/* Thi Chime */}
                    </strong>
                  </h2>
                  <MDBRow />
                  <p>Login with your credentials</p>
                  <p className='pb-4'>
                    This platform is a single window to all your digital transformation needs. 
                  </p>
                  {/* Added Aligned Items Centre in MDBRow */}
                  <MDBRow className='d-flex flex-row justify-content-center align-items-center row'>
                      {/* <div className='form-header purple-gradient'>
                        <h3>
                          <MDBIcon
                            icon='user'
                            className='mt-2 mb-2 text-white'
                          />{' '}
                          Log in:
                        </h3>
                      </div> */}
                  <div className='px-2'>
                  
                      {/* <MDBInput
                        // icon='envelope'
                        // iconClass='white-text ml-3'
                        type='text'
                        id='userId'
                        label='Your User Id'
                        autoFocus
                        required
                      /> */}
                      <MDBInput 
                      className="cust-outline form-control"
                      label='Your User Id' 
                      id='userId' 
                      type='text'
                      autoFocus
                      required  
                      outline
                      
                      />
                  </div>
                  <div className='px-1'>
                      {/* <MDBInput
                        type='password'
                        label='Your password'
                        id='password'
                        // icon='lock'
                        // iconClass='white-text'
                        required
                      /> */}
                      <MDBInput 
                      className="cust-outline form-control"
                      id='password' 
                      type='password'
                      label='Your Password'
                      required  
                      outline
                      />
                  </div>
                  <div className='text-center ml-4 black-text'>
                        {/* Added Custom CSS class in Homepage.css and removed size property */}
                        <MDBBtn onClick={this.login} className='btn-cust-col'>
                          Login
                        </MDBBtn>

                      </div>
                  </MDBRow>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
        </div>
      </>
    );
  }
}

export default HomePage;
